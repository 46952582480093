<template>
        <Layout>
            <template v-if="this.$route.name == 'add-vendor'">
                <PageHeader :title="title1" :items="items"></PageHeader>
            </template>
            <template v-else>
                <PageHeader :title="title2" :items="items"></PageHeader>
            </template>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <b-form enctype="multipart/form-data" :key="key">
                                <b-form-group>
                                    <label for="input-3">Vendor Name <span style="color: red;">*</span></label>
                                    <b-form-input id="input-3" v-model="form.vendor_name" placeholder="Enter Vendor Name"
                                        :class="{
                                        'is-invalid': submitted && $v.form.vendor_name.$error,
                                        }">
                                    </b-form-input>
                                    <div v-if="submitted && !$v.form.vendor_name.required" class="invalid-feedback">
                                        Vendor Name is required.
                                    </div>
                                </b-form-group>
                                <b-form-group>
                                    <label for="input-multi">Channel Type <span style="color: red;">*</span></label>
                                    <b-form-select id="input-multi" v-model="form.channel_type" :options="channelType"
                                    value-field="value" text-field="title" :class="{
                                        'is-invalid': submitted && $v.form.channel_type.$error,
                                    }">
                                    </b-form-select>
                                    <div v-if="submitted && !$v.form.channel_type.required" class="invalid-feedback">
                                    Select Channel Type.
                                    </div>
                                </b-form-group>
                                <b-form-group>
                                    <label for="input-3">API Url <span style="color: red;">*</span></label>
                                    <b-form-input id="input-3" v-model="form.api_url" placeholder="Enter API Url"
                                        :class="{
                                        'is-invalid': submitted && $v.form.api_url.$error,
                                        }">
                                    </b-form-input>
                                    <div v-if="submitted && !$v.form.api_url.required" class="invalid-feedback">
                                        API Url is required.
                                    </div>
                                </b-form-group>
                                <b-form-group>
                                    <label for="input-3">
                                        <span v-if="form.channel_type == 'email'">Username / API Key <span style="color: red;">*</span></span> 
                                        <span v-if="form.channel_type == 'sms'">API Key / Key <span style="color: red;">*</span></span> 
                                        <span v-if="form.channel_type == 'whatsapp'">Header Authentication <span style="color: red;">*</span></span>
                                        <span v-if="form.channel_type != 'email' && form.channel_type != 'sms' && form.channel_type != 'whatsapp'">API Key <span style="color: red">*</span> </span>
                                    </label>
                                    <b-form-input id="input-3" v-model="form.api_key" :placeholder="form.channel_type == 'email' ? 'Enter Username' : 'Enter API Key' && form.channel_type == 'sms' ? 'Enter Sender Name' : 'Enter API Key' && form.channel_type == 'whatsapp' ? 'Enter Header Authentication' : 'Enter API Key'"
                                        :class="{
                                        'is-invalid': submitted && $v.form.api_key.$error,
                                        }">
                                    </b-form-input>
                                    <div v-if="submitted && !$v.form.api_key.required" class="invalid-feedback">
                                        API Key is required.
                                    </div>
                                </b-form-group>
                                <b-form-group>
                                    <label for="input-3"> 
                                        <span v-if="form.channel_type == 'email'">Password / Secret Key <span style="color: red;">*</span></span>
                                        <span v-if="form.channel_type == 'sms'">Sender Name / Secret Key <span style="color: red;">*</span></span>
                                        <span v-if="form.channel_type == 'whatsapp'">Secret Key / Sender Number <span style="color: red;">*</span></span>
                                        <span v-if="form.channel_type != 'email' && form.channel_type != 'sms' && form.channel_type != 'whatsapp'">API Secret <span style="color: red">*</span> </span>
                                    </label>
                                    <b-form-input id="input-3" v-model="form.api_secret" :placeholder="form.channel_type == 'email' ? 'Enter Password' : 'Enter API Secret' && form.channel_type == 'sms' ? 'Enter Key' : 'Enter API Secret' && form.channel_type == 'whatsapp' ? 'Enter Secret Key / Sender Number' : 'Enter API Secret'"
                                        :class="{
                                        'is-invalid': submitted && $v.form.api_secret.$error,
                                        }">
                                    </b-form-input>
                                    <div v-if="submitted && !$v.form.api_secret.required" class="invalid-feedback">
                                        API Secret is required.
                                    </div>
                                </b-form-group>
                                <b-button type="submit" variant="primary" class="mr-2" v-if="$route.name == 'edit-vendor'"
                                    @click.prevent="updateVendor($route.params.id)">Update Data</b-button>
                                <b-button type="submit" variant="primary" class="mr-2" v-else-if="$route.name == 'add-vendor'"
                                    @click.prevent="submitVendorData($route.params.id, $route.params.forum_id)">Save Data</b-button>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
</template>
<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import forumToolVendorMixin from "../../../../mixins/ModuleJs/forum-vendor";
import { required } from "vuelidate/lib/validators";

export default {
    mixins: [MixinRequest, forumToolVendorMixin],
    data() {
        return {
            submitted: false,
            title1: "Add Vendor",
            title2: "Edit Vendor",
            items: [
                {
                text: "Back",
                href: "/forum-settings/" + this.$route.params.id + "/" + this.$route.params.forum_id,
                },
                {
                text: "Data",
                },
            ],
        };
    },
    validations: {
        form: {
            api_key: { required },
            api_secret: { required },
            api_url: { required },
            vendor_name: { required },
            channel_type: { required },
        },
    },
    components: {
        Layout,         
        PageHeader,
    },
}
</script>