var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-vendor')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{key:_vm.key,attrs:{"enctype":"multipart/form-data"}},[_c('b-form-group',[_c('label',{attrs:{"for":"input-3"}},[_vm._v("Vendor Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                                'is-invalid': _vm.submitted && _vm.$v.form.vendor_name.$error,
                                },attrs:{"id":"input-3","placeholder":"Enter Vendor Name"},model:{value:(_vm.form.vendor_name),callback:function ($$v) {_vm.$set(_vm.form, "vendor_name", $$v)},expression:"form.vendor_name"}}),(_vm.submitted && !_vm.$v.form.vendor_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Vendor Name is required. ")]):_vm._e()],1),_c('b-form-group',[_c('label',{attrs:{"for":"input-multi"}},[_vm._v("Channel Type "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-select',{class:{
                                'is-invalid': _vm.submitted && _vm.$v.form.channel_type.$error,
                            },attrs:{"id":"input-multi","options":_vm.channelType,"value-field":"value","text-field":"title"},model:{value:(_vm.form.channel_type),callback:function ($$v) {_vm.$set(_vm.form, "channel_type", $$v)},expression:"form.channel_type"}}),(_vm.submitted && !_vm.$v.form.channel_type.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Select Channel Type. ")]):_vm._e()],1),_c('b-form-group',[_c('label',{attrs:{"for":"input-3"}},[_vm._v("API Url "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                                'is-invalid': _vm.submitted && _vm.$v.form.api_url.$error,
                                },attrs:{"id":"input-3","placeholder":"Enter API Url"},model:{value:(_vm.form.api_url),callback:function ($$v) {_vm.$set(_vm.form, "api_url", $$v)},expression:"form.api_url"}}),(_vm.submitted && !_vm.$v.form.api_url.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" API Url is required. ")]):_vm._e()],1),_c('b-form-group',[_c('label',{attrs:{"for":"input-3"}},[(_vm.form.channel_type == 'email')?_c('span',[_vm._v("Username / API Key "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]):_vm._e(),(_vm.form.channel_type == 'sms')?_c('span',[_vm._v("API Key / Key "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]):_vm._e(),(_vm.form.channel_type == 'whatsapp')?_c('span',[_vm._v("Header Authentication "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]):_vm._e(),(_vm.form.channel_type != 'email' && _vm.form.channel_type != 'sms' && _vm.form.channel_type != 'whatsapp')?_c('span',[_vm._v("API Key "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]):_vm._e()]),_c('b-form-input',{class:{
                                'is-invalid': _vm.submitted && _vm.$v.form.api_key.$error,
                                },attrs:{"id":"input-3","placeholder":_vm.form.channel_type == 'email' ? 'Enter Username' : 'Enter API Key' && _vm.form.channel_type == 'sms' ? 'Enter Sender Name' : 'Enter API Key' && _vm.form.channel_type == 'whatsapp' ? 'Enter Header Authentication' : 'Enter API Key'},model:{value:(_vm.form.api_key),callback:function ($$v) {_vm.$set(_vm.form, "api_key", $$v)},expression:"form.api_key"}}),(_vm.submitted && !_vm.$v.form.api_key.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" API Key is required. ")]):_vm._e()],1),_c('b-form-group',[_c('label',{attrs:{"for":"input-3"}},[(_vm.form.channel_type == 'email')?_c('span',[_vm._v("Password / Secret Key "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]):_vm._e(),(_vm.form.channel_type == 'sms')?_c('span',[_vm._v("Sender Name / Secret Key "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]):_vm._e(),(_vm.form.channel_type == 'whatsapp')?_c('span',[_vm._v("Secret Key / Sender Number "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]):_vm._e(),(_vm.form.channel_type != 'email' && _vm.form.channel_type != 'sms' && _vm.form.channel_type != 'whatsapp')?_c('span',[_vm._v("API Secret "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]):_vm._e()]),_c('b-form-input',{class:{
                                'is-invalid': _vm.submitted && _vm.$v.form.api_secret.$error,
                                },attrs:{"id":"input-3","placeholder":_vm.form.channel_type == 'email' ? 'Enter Password' : 'Enter API Secret' && _vm.form.channel_type == 'sms' ? 'Enter Key' : 'Enter API Secret' && _vm.form.channel_type == 'whatsapp' ? 'Enter Secret Key / Sender Number' : 'Enter API Secret'},model:{value:(_vm.form.api_secret),callback:function ($$v) {_vm.$set(_vm.form, "api_secret", $$v)},expression:"form.api_secret"}}),(_vm.submitted && !_vm.$v.form.api_secret.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" API Secret is required. ")]):_vm._e()],1),(_vm.$route.name == 'edit-vendor')?_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.updateVendor(_vm.$route.params.id)}}},[_vm._v("Update Data")]):(_vm.$route.name == 'add-vendor')?_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitVendorData(_vm.$route.params.id, _vm.$route.params.forum_id)}}},[_vm._v("Save Data")]):_vm._e()],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }